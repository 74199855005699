/* eslint-disable */

import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    getContentTopics,
    postContentTopic,
} from '../../../http-actions/personalized-content-actions';
import { AuthContext } from '../../../providers/auth-provider';

interface PropTypes {}

const ContentTopicCreateForm = (props: PropTypes) => {
    const { state: authState } = useContext(AuthContext);
    const [topicOptions, setTopicOptions] = useState([]);

    useEffect(() => {
        getContentTopics(authState.token).then((topics) =>
            setTopicOptions(topics.map((t: ContentTopic) => ({ label: t.name, value: t.name }))),
        );
    }, []);

    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        postContentTopic(authState.token, data);
    };

    return (
        <>
            <div style={{ maxWidth: '500px' }}>
                <Typography component="h4" variant="h5">
                    <Trans i18nKey="createContentTopic" />
                </Typography>
                {/* {loginError && <Typography color="error">{loginError}</Typography>} */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* register your input into the hook by invoking the "register" function */}
                    <TextField
                        sx={{ mt: 2 }}
                        label={t('topic')}
                        fullWidth
                        helperText={errors.topic ? errors.topic.message : ''}
                        error={!!errors.topic}
                        {...register('name', { required: true })}
                    />
                    <TextField
                        sx={{ mt: 2 }}
                        label={t('description')}
                        fullWidth
                        helperText={errors.description ? errors.description.message : ''}
                        error={!!errors.description}
                        {...register('description')}
                    />
                    <Box display="flex" justifyContent="space-between">
                        <Button
                            color="inherit"
                            sx={{
                                margin: (theme) => theme.spacing(1, 0),
                            }}
                            type="submit"
                        >
                            <Trans i18nKey="create" />
                        </Button>
                        <Button
                            color="inherit"
                            sx={{
                                margin: (theme) => theme.spacing(1, 0),
                            }}
                            onClick={() => {}}
                        >
                            <Trans i18nKey="close" />
                        </Button>
                    </Box>
                </form>
            </div>
        </>
    );
};

export default ContentTopicCreateForm;
