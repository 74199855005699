import { Box } from '@mui/material';
import React from 'react';
import ContentTopicCreateForm from '../../../components/forms/personalized-content/content-topic-create-form';
import PersonalizedContentCreateForm from '../../../components/forms/personalized-content/personalized-content-create-form';
import ProgrammingLanguageCreateForm from '../../../components/forms/personalized-content/programming-language-create-form';
import TestingForm from '../../../components/forms/personalized-content/testing-form';
import RestrictedByRole from '../../../components/restricted-by-role';

const ContentCreation = (props: JSX.IntrinsicAttributes) => (
    <Box mx={2}>
        <RestrictedByRole role="ADMIN">
            {/* <TestingForm/> */}
            <ProgrammingLanguageCreateForm />
            <ContentTopicCreateForm />
            <PersonalizedContentCreateForm />
        </RestrictedByRole>
    </Box>
);

export default ContentCreation;
