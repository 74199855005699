/* eslint-disable */
import { Button, TextField, Typography, Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { FeatureFlagContext } from '../../../providers/feature-flag-provider';
import {
    getProgrammingLanguages,
    postProgrammingLanguage,
} from '../../../http-actions/personalized-content-actions';
import { AuthContext } from '../../../providers/auth-provider';

interface PropTypes {}

const ProgrammingLanguageCreateForm = (props: PropTypes) => {
    const { state: featureFlags } = useContext(FeatureFlagContext);

    const { state: authState } = useContext(AuthContext);
    const [languageOptions, setLanguageOptions] = useState([{ label: 'test', value: 'test' }]);

    useEffect(() => {
        getProgrammingLanguages(authState.token).then((languages) =>
            setLanguageOptions(
                languages.map((l: ProgrammingLanguage) => ({ label: l.name, value: l.name })),
            ),
        );
    }, []);

    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        postProgrammingLanguage(authState.token, data);
    };

    return (
        <>
            <div style={{maxWidth: '500px'}}>
                <Typography component="h4" variant="h5">
                    <Trans i18nKey="createProgrammingLanguage" />
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        sx={{ mt: 2 }}
                        label={t('programmingLanguage')}
                        fullWidth
                        helperText={errors.language ? errors.language.message : ''}
                        error={!!errors.language}
                        {...register('name', { required: true })}
                    />
                    <TextField
                        sx={{ mt: 2 }}
                        label={t('description')}
                        fullWidth
                        helperText={errors.description ? errors.description.message : ''}
                        error={!!errors.description}
                        {...register('description')}
                    />
                    <Box display="flex" justifyContent="space-between">
                        <Button
                            color="inherit"
                            sx={{ margin: theme => theme.spacing(1, 0) }}
                            type="submit"
                        >
                            <Trans i18nKey="create" />
                        </Button>
                        <Button
                            color="inherit"
                            sx={{ margin: theme => theme.spacing(1, 0) }}
                            onClick={() => {}}
                        >
                            <Trans i18nKey="close" />
                        </Button>
                    </Box>
                </form>
            </div>
        </>
    );
};

export default ProgrammingLanguageCreateForm;
