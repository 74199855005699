/* eslint-disable */
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
// import AceEditor from 'react-ace';
const AceEditor = typeof window !== 'undefined' ? require('react-ace').default : null
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { getContentTypes } from '../../../http-actions/personalized-content-actions';
import { AuthContext } from '../../../providers/auth-provider';

interface PropTypes {
    register: any;
    errors: any;
    setValue: any;
    control: any;
    order: number;
}

const PersonalizedContentPart = (props: PropTypes) => {
    const { t } = useTranslation();

    const { register, errors, setValue, order, control } = props;

    const { state: authState } = useContext(AuthContext);
    const [contentType, setContentType] = useState();
    const [typeOptions, setTypeOptions] = useState([]);
    const [content, setContent] = useState('');

    const contentField = `part${order}.content`;
    const contentTypeField = `part${order}.contentType`;

    useEffect(() => {
        getContentTypes(authState.token).then((types) =>
            setTypeOptions(types.map((t: ContentType) => ({ label: t.name, value: t.name }))),
        );
    }, []);

    const handleContentChange = (value: string) => {
        setValue(contentField, value);
        setContent(value);
    };

    const contentTypeError = errors[`part${order}`]?.contentType;
    const contentError = errors[`part${order}`]?.content;
    return (
        <Box pt={5}>
            <Controller
                name={`part${order}.contentType`}
                control={control}
                rules={{ required: { value: true, message: 'Content type is required' } }}
                render={() =>
                    <Select
                        placeholder={t('contentType')}
                        options={typeOptions}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} // Put dropdown before ace editor
                    />
                }
            />
            {contentTypeError?.message}
            {JSON.stringify(errors[`part${order}.contentType`])}
            <Box display="flex" flexDirection="column" flexGrow={1} width="100%" pt={1}>
                <Controller
                    name={`part${order}.content`}
                    control={control}
                    rules={{ required: { value: true, message: 'Content is required' } }}
                    render={() =>
                        <AceEditor
                            value={content}
                            onChange={handleContentChange}
                            theme="github"
                            mode="markdown"
                            fontSize="18px"
                            height="400px"
                            width="100%"
                        />
                    }
                />
                {contentError?.message}
            </Box>
            {contentType === 'code-ace' && (
                <FormControlLabel
                    {...register()}
                    // inputRef={register()}
                    control={
                        <Checkbox
                            name={`part${order}.codeExecutionEnabled`}
                            color="primary"
                        />
                    }
                    label={t('codeExecutionEnabled')}
                />
            )}
        </Box>
    );
};

export default PersonalizedContentPart;
