/* eslint-disable */

import { Box, Button, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAppDispatch } from '../../../hooks/redux';
import {
    getContentTopics,
    getProgrammingLanguages,
    postPersonalizedContent,
} from '../../../http-actions/personalized-content-actions';
import { AuthContext } from '../../../providers/auth-provider';
import { FeatureFlagContext } from '../../../providers/feature-flag-provider';
import PersonalizedContentPart from './personalized-content-form-part';

interface PropTypes {}

const PersonalizedContentCreateForm = (props: PropTypes) => {

    const { state: authState } = useContext(AuthContext);
    const [languageOptions, setLanguageOptions] = useState([{ label: 'test', value: 'test' }]);
    const [topicOptions, setTopicOptions] = useState([]);
    const [loginError, setLoginError] = useState(null);
    const [showPasswordResetForm, setShowPasswordResetForm] = useState(false);
    const [nParts, setNParts] = useState(1);
    const parts = [...Array(nParts).keys()];

    const languageField = 'language';
    const topicField = 'topic';

    useEffect(() => {
        getProgrammingLanguages(authState.token).then((languages) =>
            setLanguageOptions(
                languages.map((l: ProgrammingLanguage) => ({ label: l.name, value: l.name })),
            ),
        );
        getContentTopics(authState.token).then((topics) =>
            setTopicOptions(topics.map((t: ContentTopic) => ({ label: t.name, value: t.name }))),
        );
    }, []);

    const { t } = useTranslation();

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        const personalizedContent = { language: data.language.value, topic: data.topic.value };
        const parts = Object.values(_.omit(data, ['language', 'topic']));
        personalizedContent.parts = parts.map((part) => ({
            ...part,
            contentType: part.contentType.value,
        }));
        console.log(personalizedContent)
        postPersonalizedContent(authState.token, personalizedContent);
    };

    return (
        <>
            <div style={{maxWidth: '500px'}}>
                <Typography component="h4" variant="h5">
                    <Trans i18nKey="createPersonalizedContent" />
                </Typography>
                {/* {loginError && <Typography color="error">{loginError}</Typography>} */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* register your input into the hook by invoking the "register" function */}
                    <Controller
                        name="language"
                        control={control}
                        rules={{ required: { value: true, message: 'Content language is required' } }}
                        render={() =>
                            <Select
                                placeholder={t('programmingLanguage')}
                                options={languageOptions}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} // Put dropdown before ace editor
                            />
                        }
                    />
                    {errors?.language?.message}
                    <Controller
                        name="topic"
                        control={control}
                        rules={{ required: { value: true, message: 'Content topic is required' } }}
                        render={() =>
                            <Select
                                placeholder={t('contentTopics')}
                                noOptionsMessage={(input) =>
                                    `No options avaiable for input ${input}`
                                }
                                options={topicOptions}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} // Put dropdown before ace editor
                            />
                        }
                    />
                    {errors?.topic?.message}
                    {parts.map((part) => (
                        <PersonalizedContentPart
                            key={part}
                            register={register}
                            errors={errors}
                            control={control}
                            setValue={setValue}
                            order={part}
                        />
                    ))}
                    <Box display="flex" justifyContent="space-between">
                        <Button type="submit" onClick={() => setNParts(nParts + 1)}>
                            <Trans i18nKey="Add part" />
                        </Button>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Button sx={{ margin: theme => theme.spacing(1, 0) }} onClick={() => {}}>
                            <Trans i18nKey="close" />
                        </Button>
                        <Button sx={{ margin: theme => theme.spacing(1, 0) }} type="submit">
                            <Trans i18nKey="create" />
                        </Button>
                    </Box>
                </form>
            </div>
        </>
    );
};

export default PersonalizedContentCreateForm;
