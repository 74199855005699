import config from '../config';
import { getAxiosClient } from './axios-client';
import { handleResponseError, handleResponseSuccess } from './http-response-handler';

const axios = getAxiosClient(`${config.ASSIGNMENT_API_BASE_URL}/api/personalized-content`);

export const getProgrammingLanguages = (token: string) => {
    console.log('fetching languages');
    return axios
        .get('programming-languages', {
            headers: { Authorization: token },
        })
        .then(
            (response) =>
                handleResponseSuccess(response, 'retrieved personalized content languages'),
            handleResponseError,
        );
};

export const getContentTopics = (token: string) => {
    console.log('fetching topics');
    return axios
        .get('content-topics', {
            headers: { Authorization: token },
        })
        .then(
            (response) => handleResponseSuccess(response, 'retrieved personalized content topics'),
            handleResponseError,
        );
};

export const getContentTypes = (token: string) => {
    console.log('fetching content types');
    return axios
        .get('content-types', {
            headers: { Authorization: token },
        })
        .then(
            (response) => handleResponseSuccess(response, 'retrieved personalized content types'),
            handleResponseError,
        );
};

export const getPersonalizedContents = (token: string, uuid: string) => {
    console.log('fetching content types');
    return axios
        .get(`${uuid}`, {
            headers: { Authorization: token },
        })
        .then(
            (response) => handleResponseSuccess(response, 'retrieved personalized content types'),
            handleResponseError,
        );
};

export const postProgrammingLanguage = (token: string, language: ProgrammingLanguage) => {
    console.log(`posting new programming language ${language}`);
    return axios
        .post('programming-languages', language, {
            headers: { Authorization: token },
        })
        .then(
            (response) => handleResponseSuccess(response, 'created new content topic'),
            handleResponseError,
        );
};

export const postContentTopic = (token: string, topic: ContentTopic) => {
    console.log(`posting new topic ${topic}`);
    return axios
        .post('content-topics', topic, {
            headers: { Authorization: token },
        })
        .then(
            (response) => handleResponseSuccess(response, 'created new content topic'),
            handleResponseError,
        );
};

export const postPersonalizedContent = (
    token: string,
    personalizedContent: PersonalizedContent,
) => {
    console.log(
        `posting new personalized content ${
            (personalizedContent.language, personalizedContent.topic)
        }`,
    );
    return axios
        .post('', personalizedContent, {
            headers: { Authorization: token },
        })
        .then(
            (response) => handleResponseSuccess(response, 'created new personalized content'),
            handleResponseError,
        );
};
